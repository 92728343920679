import { useState, useEffect } from "react";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";

import Edit from "../../assets/ic_edit.png";
import Delete from "../../assets/ic_delete.png";
import Confirm from "../../assets/ic_confirm.png";
import Cancel from "../../assets/ic_cancel.png";

import User from "../../assets/ic_user.png";
import Alga from "../../assets/poi/ic_poi_alga.png";
import Anchor from "../../assets/poi/ic_poi_anchor.png";
import Boat from "../../assets/poi/ic_poi_boat.png";
import Checker from "../../assets/poi/ic_poi_checker.png";
import Cross from "../../assets/poi/ic_poi_cross.png";
import Fish from "../../assets/poi/ic_poi_fish.png";
import Heart from "../../assets/poi/ic_poi_heart.png";
import House from "../../assets/poi/ic_poi_house.png";
import Leaf from "../../assets/poi/ic_poi_leaf.png";
import Pin from "../../assets/poi/ic_poi_pin.png";
import Star from "../../assets/poi/ic_poi_star.png";
import Wreck from "../../assets/poi/ic_poi_wreck.png";
import Else from "../../assets/poi/ic_poi_else.png";

import "./TaiderRow.css";

const states = {
    READONLY : 0,
    EDIT : 1,
    NEW : 2,
}

export default function TaiderRow(props){
    const [state, setState] = useState(states.READONLY);
    useEffect(()=>{
        if(props.new){
            setState(states.NEW)
        }
    },[]);

    const deleteRow = () => {
        showConfirmationDialog(()=>{
            props.delete(props.token, props.id).then((response)=>response?props.callback(props.token):null);
        });
    }

    const cancelRow = () => {
        if(state === states.NEW){
            props.delete(props.id);
        }else{
            setState(states.READONLY);
        }
    }

    const saveRow = () => {
        const row = document.getElementById(props.id)
        const urlSearchParams = new URLSearchParams({"id": props.id});
        props.row.map(
            (field) => {
                if(field.type === "checkbox"){
                    const input = row.querySelector(`input[name="${field.key}"`);
                    const value = input.checked;
                    console.log(`adding key: ${field.key}; value: ${value};`);
                    urlSearchParams.append(field.key, value);
                }else if(field.type === "text"){
                    const input = row.querySelector(`input[name="${field.key}"`);
                    const value = input.value;
                    if(field.validate && field.validate.includes("notempty") && !value){
                        alert(`${field.key} can not be empty!`);
                        return null;
                    }
                    if(field.validate && field.validate.includes("notnan") && isNaN(value)){
                        alert(`${field.key} must be number!`);
                        return null;
                    }
                    console.log(`adding key: ${field.key}; value: ${value};`);
                    urlSearchParams.append(field.key, value);
                    return null;
                }
                return null
            }
        )
        if(state === states.NEW){
            setState(states.READONLY);
            props.save(props.token, urlSearchParams).then((response)=>response?props.callback(props.token):null);
        }else{
            setState(states.READONLY);
            props.edit(props.token, urlSearchParams).then((response)=>response?props.callback(props.token):null);
        }
    }
    
    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const formatTime = (totalSeconds) => {
        let hours = parseInt(totalSeconds / 3600);
        let seconds = parseInt(totalSeconds % 3600);
        let minutes = parseInt(seconds / 60);
        seconds = parseInt(seconds % 60);
        let hoursStr = hours.toString().padStart(2, '0');
        let minutesStr = minutes.toString().padStart(2, '0');
        let secondsStr = seconds.toString().padStart(2, '0');
        return hoursStr + ":" + minutesStr + ":" + secondsStr
    }

    const formatRemainingTime = (start, durationDays) => {
        let duration = durationDays * 86400 // days to seconds: 24*60*60
        let now = parseInt(Date.now()/1000);
        let end = parseInt(start) + duration
        let seconds = end - now;
        if(seconds <= 0){
            return null;
        }
        return formatTime(seconds);
    }

    const displayIcon = (iconName) => {
        var iconSrc = null;
        switch (iconName) {
            case "ALGA":
                iconSrc = Alga;
                break;
            case "ANCHOR":
                iconSrc = Anchor;
                break;
            case "BOAT":
                iconSrc = Boat;
                break;
            case "CHECKER":
                iconSrc = Checker;
                break;
            case "CROSS":
                iconSrc = Cross;
                break;
            case "FISH":
                iconSrc = Fish;
                break;
            case "HEART":
                iconSrc = Heart;
                break;
            case "HOUSE":
                iconSrc = House;
                break;
            case "LEAF":
                iconSrc = Leaf;
                break;
            case "PIN":
                iconSrc = Pin;
                break;                
            case "STAR":
                iconSrc = Star;
                break;
            case "WRECK":
                iconSrc = Wreck;
                break;
            default:
                iconSrc = Else;
                break;
        }
        return (<img src={iconSrc} alt={iconName}/>);
    }

    const readonlyRow = (<>
        {props.row.map(
            (field) => {
                let fieldWidth = (100/(props.row.length+1))*(field.size?field.size:1)+"%";
                let className = "";
                let content = "";
                if(field.type === "checkbox" && field.value === true){
                    className = "field field-positive";
                    content = "YES";
                }else if(field.type === "checkbox" && field.value === false){
                    className = "field field-negative";
                    content = "NO";
                }else if(field.type === "date"){
                    className = "field";
                    content = formatDate(field.value);
                }else if(field.type === "time"){
                    let timeLeft = formatRemainingTime(props.row.find(i => i.key === "date").value, field.value)
                    if(timeLeft){
                        className = "field";
                        content = timeLeft
                    }else{
                        className = "field field-negative";
                        content = "EXPIRED";
                    }
                }else if(field.type === "icon"){
                    className = "field";
                    content = displayIcon(field.value);
                }else if(field.type === "picture"){
                    className = "field";
                    content = (<img src={field.value} alt=""/>);
                }else{
                    className = "field";
                    content = (field.value!==null && field.value!==undefined)?`${field.value} ${field.suffix?field.suffix:""}`:null;
                }
                return <span key={field.key} className={className} style={{width: fieldWidth}}>
                    {content}
                </span>;
            }
        )}
        <span className={"field field-actions"} style={{width: (100/(props.row.length+1))+"%"}}>
            {props.edit?<button onClick={()=>setState(states.EDIT)}>
                <img src={Edit} alt="Edit"/>
            </button>:null}
            {props.details?<button onClick={props.details}>
                Details
            </button>:null}
            {props.account?<button onClick={props.account}>
                <img src={User} alt="User"/>
            </button>:null}
            {props.delete?<button onClick={()=>deleteRow()}>
                <img src={Delete} alt="Delete"/>
            </button>:null}
        </span>
    </>);

    const editableRow = (<>
        {props.row.map(
            (field) => {
                let fieldWidth = (100/(props.row.length+1))*(field.size?field.size:1)+"%";
                let className = "";
                let content = "";
                if(field.type === "checkbox"){
                    className = "field field-editable";
                    content = (<label className="checkbox-container">
                        <input type="checkbox" defaultChecked={field.value} name={field.key}/>
                        <span className="checkmark"></span>
                    </label>);
                }else if(field.type === "text"){
                    className = "field field-editable";
                    content = (<input type="text" defaultValue={field.value} name={field.key}/>);
                }
                return <span key={field.key} className={className} style={{width: fieldWidth}}>
                    {content}
                </span>;
            }
        )}
        <span className="field field-actions" style={{width: (100/(props.row.length+1))+"%"}}>
            <button onClick={()=>saveRow()}>
                <img src={Confirm} alt="Confirm"/>
            </button>
            <button onClick={()=>cancelRow()}>
                <img src={Cancel} alt="Cancel"/>
            </button>
        </span>
    </>);

    const row = state===states.READONLY?readonlyRow:editableRow;

    return(
        <div id={props.id} className="taiderrow">
            {row}
        </div>
    );
}