import { useNavigate } from "react-router-dom";
import User from "../../assets/ic_user.png";
import Delete from "../../assets/ic_delete.png";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import "./Plan.css";

export default function Plan(props) {
    const navigate = useNavigate();

    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const row = (<>
        <span className={"planfield planfield-planname"}>{props.plan.name}</span>
        <span className={"planfield planfield-date"}>{formatDate(props.plan.timestamp)}</span>
        <span className={"planfield planfield-deleted " + (props.plan.deleted?"planfield-negative":"planfield-positive")}>{props.plan.deleted?"YES":"NO"}</span>
        <span className={"planfield planfield-actions"}>
            <button onClick={()=>navigate(`/plan/${props.plan.id}`)}>
                Details
            </button>
            <button onClick={()=>navigate(`/user/${props.plan.user_id}`)}>
                <img src={User} alt="User details"/>
            </button>
            <button onClick={()=>deleteRow()}>
                <img src={Delete} alt="Delete"/>
            </button>
        </span>
    </>);

    const deleteRow = () => {
        console.log("deleting plan");
        showConfirmationDialog(()=>{
            props.delete(props.token, props.plan.id).then((response)=>response?props.callback(props.token):null);
        });
    }

    return(
        <div id={props.id} className="plan">
            {row}
        </div>
    );
}