import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPlans, getUserPlans, deletePlan } from "../../components/TaiderAPI/TaiderAPI.js";
import Plan from "../../components/Plan/Plan.js";
import "./Plans.css";

export default function Plans(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("current id is " + id);
        loadPlans();
    }, [id]);

    const loadPlans = async () => {
        if(id != null){
            loadUserPlans(id);
            return;
        }
        if(taiderUser.userData == null){
            return;
        }
        getPlans(taiderUser.userData.token)
            .then(plans => plans?setRows(mapPlans(plans)):null)
            .catch(handleError);
    };

    const loadUserPlans = async (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getUserPlans(taiderUser.userData.token, id)
            .then(plans => plans?setRows(mapPlans(plans)):null)
            .catch(redirectToAllPlans);
    };

    const mapPlans = (plans) => {
        console.log("mapping Plans");
        var mappedPlans = plans.map(plan => <Plan key={plan.id} token={taiderUser.userData.token} plan={plan} delete={deletePlan} callback={loadPlans} />)
        return mappedPlans;
    };
    
    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }
    
    const redirectToAllPlans = () => {
        alert("Plan with such id does not exist");
        navigate("/plans");
    }

    const header = (<div className="header-row">
        <span className="plansheader plansheader-name">Name</span>
        <span className="plansheader plansheader-date">Date added</span>
        <span className="plansheader plansheader-deleted">Deleted?</span>
        <span className="plansheader plansheader-actions"></span>
    </div>);

    return (<div id="plans">
        {header}
        {rows}
    </div>)
}