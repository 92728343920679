import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getPurchases, deletePurchase, getSubs } from "../../components/TaiderAPI/TaiderAPI";
import "./Purchases.css";
import TaiderRow from "../../components/TaiderRow/TaiderRow.js";

export default function Purchases(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const subs = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        loadPurchases()
    }, []);

    const loadPurchases = async () => {
        if(taiderUser.userData == null){
            return;
        }
        await getSubs(taiderUser.userData.token)
            .then(receivedSubs => receivedSubs?setSub(receivedSubs):null)
            .catch(handleError)
        getPurchases(taiderUser.userData.token)
            .then(purchases => purchases?setRows(mapPurchases(purchases)):null)
            .catch(handleError);
    };

    const setSub = (receivedSubs) => {
        subs.current = receivedSubs;
    } 

    const mapPurchases = (purchases) => {
        console.log("saving purchases");
        var mappedPurchases = purchases.map(purchase => <TaiderRow 
            id={purchase.id}
            key={purchase.id}
            token={taiderUser.userData.token} 
            row={[
                {key: "subname", value: purchase.subName, type: "text"},
                {key: "username", value: purchase.userName, type: "text"},
                {key: "date", value: purchase.timestamp, type: "date"},
                {key: "duration", value: subs.current?subs.current.find(sub=>sub.name === purchase.subName).duration:null, type:"time"},
            ]}
            account={()=>navigate(`/user/${purchase.user_id}`)} 
            delete={deletePurchase} 
            callback={loadPurchases} 
        />)
        return mappedPurchases;
    };
    
    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const header = (<div className="header-row">
        <span className="purchasesheader purchasesheader-subname">Sub name</span>
        <span className="purchasesheader purchasesheader-username">User name</span>
        <span className="purchasesheader purchasesheader-date">Purchase date</span>
        <span className="purchasesheader purchasesheader-time">Remaining time</span>
        <span className="purchasesheader purchasesheader-actions">Actions</span>
    </div>);

    return (<div id="purchases">
        {header}
        {rows}
    </div>)
}