import { useParams, useNavigate } from "react-router-dom";
import { getTrip } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext } from "react";
import Leaflet from "../../components/Leaflet/Leaflet.js";
import "./Trip.css";

export default function Trip(){
    const [ taiderUser, ] = useContext(UserContext);
    const [ trip, setTrip ] = useState(null);
    const [ route, setRoute ] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadTrip(id)
    }, []);
    
    const loadTrip = (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getTrip(taiderUser.userData.token, id)
            .then(result => result?mapTrip(result):null)
            .catch(redirectToCodes);
    };

    const formatDate = (timestamp) => {
        if(timestamp == null){
            return null;
        }
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const mapTrip = (trip) => {
        console.log(trip);
        let mappedTrip = (<div id="trip-info">
            <div className="trip-info-row">
                <span className="trip-info-label">Trip name</span>
                <span className="trip-info-field">{trip.name}</span>
            </div>
            <div className="trip-info-row">
                <span className="trip-info-label">Creation date</span>
                <span className="trip-info-field">{formatDate(trip.timestamp)}</span>
            </div>
            <div className="trip-info-row">
                <span className="trip-info-label">User's name</span>
                <span className="trip-info-field">{trip.user_name?trip.user_name:"add 'user_name' field to getTrip endpoint"}</span>
            </div>
            <div className="trip-info-row">
                <span className="trip-info-label">Steps' count</span>
                <span className="trip-info-field">{trip.route.length}</span>
            </div>
        </div>);
        setTrip(mappedTrip);
        let mappedRoute = trip.route.map(step => {return [step.latitude, step.longitude]});
        setRoute(mappedRoute);
        return true;
    }

    const redirectToCodes = () => {
        alert("Code with such id does not exist");
        navigate("/codes");
    }

    const tripPage = (<div id="trip-data">
        {trip}
        {trip?<Leaflet route={route}/>:""}
    </div>)

    return (<div id="trip">{trip?tripPage:<></>}</div>);
}