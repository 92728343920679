import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { SignOutContext, UserContext } from "../../views/App/App";
import { useContext } from "react";
import "./Navbar.css";
const { REACT_APP_ENV } = process.env;

export default function NavBar() {
  const signOut = useContext(SignOutContext);
  const [user,] = useContext(UserContext);

  return (
      <nav id="navbar">
        <ul>
          <li>
            <Link to="/">
              <img src={Logo} alt="home"/>
            </Link>
          </li>
          <li>
            <Link to="/subs">Subs</Link>
          </li>
          <li>
            <Link to="/users">Users</Link>
          </li>
          <li>
            <Link to="/purchases">Purchases</Link>
          </li>
          <li>
            <Link to="/codes">Codes</Link>
          </li>
          <li>
            <Link to="/maps">Map</Link>
          </li>
          <li>
            <Link to="/pois">POIs</Link>
          </li>
          <li>
            <Link to="/trips">Trips</Link>
          </li>
          <li>
            <Link to="/plans">Plans</Link>
          </li>
          <li> 
            <Link to="/layers">Layers</Link>
          </li>
          <li className="float-right">
            <span>
              <div id="env-name">{REACT_APP_ENV}</div>
            </span>
          </li>
          <li className="float-right">
            <span>
              <img src={(user?(user.userData?(user.userData.picture?user.userData.picture:null):null):null)} alt="user"/>
            </span>
          </li>
          <li className="float-right">
            <span onClick={()=>signOut()}>
              Logout
            </span>
          </li>
        </ul>
      </nav>
  )
};