import { MapContainer, TileLayer, Polyline} from 'react-leaflet';
import './Leaflet.css';
import 'leaflet/dist/leaflet.css';

export default function Leaflet(props){
    const polylineOptions = { color: 'red', weight: '2' };

    return(
        <div className='leaflet-container'>
            <MapContainer bounds={props.route?props.route:null} scrollWheelZoom={true}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {props.route?<Polyline pathOptions={polylineOptions} positions={props.route} />:""}
            </MapContainer>,
        </div>
    );
}