
import { showLoadingModal, hideLoadingModal } from "../LoadingModal/LoadingModal";

const baseUrl = process.env.REACT_APP_API_URL;
const codes = {
    OK : 0,
    ERROR : 1,
}

//login
export const signIn = async (token) => {
    console.log("API signing in");
    showLoadingModal();
    try{
        const url = baseUrl + "/users/signin";
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
            },
            body: new URLSearchParams({
                "firebaseIdToken": token,
            }),
        }
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            hideLoadingModal();
            console.log(parsedResponse.result.token);
            return parsedResponse.result;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
} 

//subs
export const getSubs = async (token) => {    
    console.log("API getting subs");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/subs/all";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got subs - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deleteSub = async (token, id) => {
    console.log("API deleting sub");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/subs/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("deleted sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const putSub = async (token, sub) => {
    console.log("API putting sub");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/subs/" + sub.get("id");
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: sub,
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("put sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const postSub = async (token, sub) => {
    console.log("API posting sub");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/subs";
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: sub,
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("post sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//users
export const getUsers = async (token) => {    
    console.log("API getting users");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/users";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got users - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getUser = async (token, id) => {
    console.log("API getting user " + id);
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/users/" + id;
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got user - ${parsedResponse.name}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const putUser = async (token, id, tos, admin) => {
    console.log("editing user " + id);
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        console.log(token);
        console.log(tos);
        console.log(admin);
        const url = baseUrl + "/users/" + id;
        console.log(url);
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: new URLSearchParams({
                "tos_accepted": tos,
                "admin": admin
            }),
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("put sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getActiveSubs = async (token, id) => {
    console.log("API getting active subs of user " + id);
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/users/" + id + "/subs";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got active subs - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deleteActiveSub = async (token, id) => {
    console.log("API deleting active sub");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/purchases/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("deleted active sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const postActiveSub = async (token, userId, subId) => {
    console.log("API posting active sub");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/purchases";
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: new URLSearchParams({
                "user_id": userId,
                "sub_id": subId
            }),
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("post sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//purchases
export const getPurchases = async (token) => {    
    console.log("API getting purchases");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/purchases";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got purchases - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deletePurchase = async (token, id) => {
    console.log("API deleting purchase " + id);
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/purchases/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("deleted sub");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//promo codes
export const getCodes = async (token) => {    
    console.log("API getting promo codes");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/groups";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got codes - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getCode = async (token, id) => {    
    console.log("API getting promo codes");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/groups/" + id;
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got code - ${parsedResponse.id}`);
        console.log(parsedResponse);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getCodeEnabledSubs = async (token) => {    
    console.log("API getting promo code enabled subs");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/subs";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got subs for codes - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deleteCodeGroup = async (token, id) => {
    console.log("API deleting code group");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/groups/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        console.log(response);
        if(response.ok === true){
            console.log("deleted code group");
            hideLoadingModal();
            return response.statusText;
        }else{
            throw response.statusText
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deleteCode = async (token, id) => {
    console.log("API deleting code");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        console.log(response);
        if(response.ok === true){
            console.log("deleted code");
            hideLoadingModal();
            return response.statusText;
        }else{
            throw response.statusText
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const postCode = async (token, code) => {
    console.log("API posting code");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/generate";
        console.log(code.toString());
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: code,
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("post code");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const putCode = async (token, id, active) => {    
    console.log("API putting promo code");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/codes/groups/" + id;
        const options = {
            method: "PUT",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: new URLSearchParams({
                "active": active,
            }),
        };
        let response = await fetch(url, options);
        if(response.ok === true){
            console.log("code put");
            hideLoadingModal();
            return response.statusText;
        }else{
            throw response.statusText
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//maps

export const getMaps = async (token) => {
    console.log("API getting maps");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/map/list";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got maps - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const putMap = async (token, id) => {
    console.log("API activating map");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/map/" + id + "/activate";
        const options = {
            method: "PUT",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        if(response.ok === true){
            console.log("map activated");
            hideLoadingModal();
            return response.statusText;
        }else{
            throw response.statusText
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const verifyMap = async (token, file) => {
    console.log("API verifying map");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        if(file == null){
            throw new Error("no map file");
        }
        const url = baseUrl + "/map/verify";
        var data = new FormData()
        data.append("map", file, "map");
        const options = {
            method: "POST",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: data,
        };
        let response = await fetch(url, options);
        if(response.ok){
            console.log("verified map");
            let parsedResponse = await response.json();
            hideLoadingModal();
            return parsedResponse;
        }else{
            throw response.statusCode;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const uploadMap = async (token, file) => {
    console.log("API uploading map");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        if(file == null){
            throw new Error("no map file");
        }
        const url = baseUrl + "/map/upload";
        var data = new FormData()
        data.append("map", file, "map");
        const options = {
            method: "POST",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: data,
        };
        let response = await fetch(url, options);
        if(response.ok){
            console.log("uploaded map");
            let parsedResponse = await response.json();
            hideLoadingModal();
            return parsedResponse;
        }else{
            throw response.statusCode;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//poi

export const getPOIs = async (token) => {    
    console.log("API getting POIs");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/poi/list";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got POIs - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getUserPOIs = async (token, id) => {    
    console.log("API getting user's POIs");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/users/" + id + "/poi";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got POIs - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//trips

export const getTrips = async (token) => {    
    console.log("API getting Trips");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/trips/list";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got Trips - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getUserTrips = async (token, id) => {    
    console.log("API getting user's Trips");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        // const url = baseUrl + "/users/" + id + "/trip";
        const url = baseUrl + "/trips";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got trips - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}


export const getTrip = async (token, id) => {    
    console.log("API getting trip");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/trips/" + id;
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got trip - ${parsedResponse.id}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deleteTrip = async (token, id) => {
    console.log("API deleting trip");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/trips/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("deleted trip");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//plans

export const getPlans = async (token) => {    
    console.log("API getting Plans");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/plans/list";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got Plans - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const getUserPlans = async (token, id) => {    
    console.log("API getting user's Plans");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        // const url = baseUrl + "/users/" + id + "/plans";
        const url = baseUrl + "/plans";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got plans - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}


export const getPlan = async (token, id) => {    
    console.log("API getting plan");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/plans/" + id;
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got plan - ${parsedResponse.id}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const deletePlan = async (token, id) => {
    console.log("API deleting plan");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/plans/" + id;
        const options = {
            method: "DELETE",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("deleted plan");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

//layers

export const getLayers = async (token) => {
    console.log("API getting layers");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/map/layers";
        const options = {
            method: "GET",
            headers: {
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        console.log(`got layers - ${parsedResponse.length}`);
        hideLoadingModal();
        return parsedResponse;
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const putLayer = async (token, layer) => {
    console.log("API putting layer");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/map/layers/" + layer.get("id");
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: layer,
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("put layer");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}

export const putLayers = async (token, layers) => {
    console.log("API putting layer");
    showLoadingModal();
    try{
        if(token == null){
            throw new Error("no access token");
        }
        const url = baseUrl + "/map/layers";
        const options = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept-Encoding": "gzip, deflate, br",
                "Connection": "keep-alive",
                "Accept": "*/*",
                "x-access-token": token,
            },
            body: layers,
        };
        let response = await fetch(url, options);
        let parsedResponse = await response.json();
        if(parsedResponse.statusCode === codes.OK){
            console.log("put layers");
            hideLoadingModal();
            return parsedResponse.message;
        }else{
            throw parsedResponse.message;
        }
    }catch(error){
        console.error(error);
        hideLoadingModal();
        throw error;
    }
}