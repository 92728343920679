import { useParams, useNavigate } from "react-router-dom";
import { getPlan } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext } from "react";
import Leaflet from "../../components/Leaflet/Leaflet.js";
import "./Plan.css";

export default function Plan(){
    const [ taiderUser, ] = useContext(UserContext);
    const [ plan, setPlan ] = useState(null);
    const [ route, setRoute ] = useState(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        loadPlan(id)
    }, []);
    
    const loadPlan = (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getPlan(taiderUser.userData.token, id)
            .then(result => result?mapPlan(result):null)
            .catch(redirectToPlans);
    };

    const formatDate = (timestamp) => {
        if(timestamp == null){
            return null;
        }
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const mapPlan = (plan) => {
        console.log(plan);
        let mappedPlan = (<div id="plan-info">
            <div className="plan-info-row">
                <span className="plan-info-label">Plan name</span>
                <span className="plan-info-field">{plan.name}</span>
            </div>
            <div className="plan-info-row">
                <span className="plan-info-label">Creation date</span>
                <span className="plan-info-field">{formatDate(plan.timestamp)}</span>
            </div>
            <div className="plan-info-row">
                <span className="plan-info-label">User's name</span>
                <span className="plan-info-field">{plan.user_name?plan.user_name:"add 'user_name' field to getPlan endpoint"}</span>
            </div>
            <div className="plan-info-row">
                <span className="plan-info-label">Steps' count</span>
                <span className="plan-info-field">{plan.route.length}</span>
            </div>
        </div>);
        setPlan(mappedPlan);
        let mappedRoute = plan.route.map(step => {return [step.latitude, step.longitude]});
        setRoute(mappedRoute);
        return true;
    }

    const redirectToPlans = () => {
        alert("Plan with such id does not exist");
        navigate("/plans");
    }

    const planPage = (<div id="plan-data">
        {plan}
        {plan?<Leaflet route={route}/>:""}
    </div>)

    return (<div id="plan">{plan?planPage:<></>}</div>);
}