import "./Subs.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getSubs, deleteSub, putSub, postSub } from "../../components/TaiderAPI/TaiderAPI";
import { UserContext } from "../../views/App/App.js";
import Add from "../../assets/ic_add.png";
import TaiderRow from "../../components/TaiderRow/TaiderRow";

export default function Subs() {
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");    
    const navigate = useNavigate();

    useEffect(() => {
        loadSubs()
    }, []);

    const loadSubs = () => {
        if(taiderUser.userData == null){
            return;
        }
        getSubs(taiderUser.userData.token)
            .then(subs => subs?setRows(mapSubs(subs)):null)
            .catch(handleError);
    };

    const mapSubs = (subs) => {
        var mappedSubs = subs.map(sub=>{
            return <TaiderRow 
                id={sub.id} 
                key={sub.id}
                row={[
                    {key: "name", value: sub.name, size: 2, type: "text", validation: "notempty"},
                    {key: "price", value: sub.price, type: "text", suffix: " PLN", validation: "notnan"},
                    {key: "SKU", value: sub.sku, size: 2, type: "text"},
                    {key: "duration", value: sub.duration, size: 0.5, type: "text", suffix: " Days", validation: "notnan"},
                    {key: "trial", value: sub.trial, size: 0.5, type: "checkbox"},
                    {key: "codes_enabled", value: sub.codes_enabled, size: 0.5, type: "checkbox"},
                    {key: "hidden", value: sub.hidden, size: 0.5, type: "checkbox"},
                ]}
                delete={deleteSub} 
                edit={putSub}
                token={taiderUser.userData.token} 
                callback={loadSubs}
            />
        });
        return mappedSubs;
    };

    const addSub = () => {
        const timestamp = Date.now();
        setRows(rows.concat([
            <TaiderRow
                id={timestamp}
                key={timestamp}
                row={[
                    {key: "name", value: "", size: 2, type: "text", validation: "notempty"},
                    {key: "price", value: "", type: "text", suffix: " PLN", validation: "notnan"},
                    {key: "SKU", value: "", size: 2, type: "text"},
                    {key: "duration", value: "", size: 0.5, type: "text", suffix: " Days", validation: "notnan"},
                    {key: "trial", value: false, size: 0.5, type: "checkbox"},
                    {key: "codes_enabled", value: false, size: 0.5, type: "checkbox"},
                    {key: "hidden", value: false, size: 0.5, type: "checkbox"},
                ]}
                new={true}
                token={taiderUser.userData.token}
                save={postSub}
                delete={removeSub}
                callback={loadSubs}
            />
        ]))
    }

    const removeSub = (id) => {
        setRows(
            rows.filter(sub=>sub.id !== id)
        );
    }

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const header = (<div className="header-row">
        <span className="subsheader subsheader-name subheader-big">Name</span>
        <span className="subsheader subsheader-price">Price</span>
        <span className="subsheader subsheader-sku subheader-big">SKU</span>
        <span className="subsheader subsheader-duration subheader-small">Duration</span>
        <span className="subsheader subsheader-trial subheader-small">Trial</span>
        <span className="subsheader subsheader-codesenabled subheader-small">Codes enabled</span>
        <span className="subsheader subsheader-visible subheader-small">Visible</span>
        <span className="subsheader subsheader-actions">Actions</span>
    </div>);

    return (<div id="subs">    
        {header}
        {rows}
        <div id="sub-add">
            <button onClick={()=>addSub()}>
                <img src={Add} alt="add new sub"/>
            </button>
        </div>
    </div>);
};
