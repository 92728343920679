import { UserContext } from "../App/App.js";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getPOIs, getUserPOIs } from "../../components/TaiderAPI/TaiderAPI.js";
import TaiderRow from "../../components/TaiderRow/TaiderRow.js";
import "./POIs.css";

export default function POIs(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("current id is " + id);
        if(id != null){
            loadUserPOIs(id);
        }else{
            loadPOIs();
        }
    }, [id]);

    const loadPOIs = async () => {
        if(taiderUser.userData == null){
            return;
        }
        getPOIs(taiderUser.userData.token)
            .then(pois => pois?setRows(mapPOIs(pois)):null)
            .catch(handleError);
    };

    const loadUserPOIs = async (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getUserPOIs(taiderUser.userData.token, id)
            .then(pois => pois?setRows(mapPOIs(pois)):null)
            .catch(redirectToAllPOIs);
    };

    const mapPOIs = (pois) => {
        console.log("mapping POIs");
        var mappedPOIs = pois.map(poi => <TaiderRow 
            id={poi.id}
            key={poi.id} 
            token={taiderUser.userData.token} 
            row={[
                {key: "name", value: poi.name, size: 1.5, type: "text"},
                {key: "date", value: poi.timestamp, type: "date"},
                {key: "icon", value: poi.icon_name, type: "icon", size: 0.5},
                {key: "latitude", value: poi.latitude, type: "text"},
                {key: "longitude", value: poi.longitude, type: "text"},
                {key: "deleted", value: poi.deleted, size: 0.5, type: "checkbox"},
            ]}
            account={()=>navigate(`/pois/${poi.user_id}`)} 
            details={()=>navigate(`/user/${poi.user_id}`)} 
        />)
        return mappedPOIs;
    };
    
    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }
    
    const redirectToAllPOIs = () => {
        alert("User with such id does not exist");
        navigate("/pois");
    }

    const header = (<div className="header-row">
        <span className="poisheader poisheader-name">Name</span>
        <span className="poisheader poisheader-date">Date added</span>
        <span className="poisheader poisheader-icon poisheader-small">Icon</span>
        <span className="poisheader poisheader-latitude">Latitude</span>
        <span className="poisheader poisheader-longitude">longitude</span>
        <span className="poisheader poisheader-deleted poisheader-small">Deleted?</span>
    </div>);

    return (<div id="pois">
        {header}
        {rows}
    </div>)
}