import { useNavigate } from "react-router-dom";
import User from "../../assets/ic_user.png";
import Delete from "../../assets/ic_delete.png";
import { showConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import "./Trip.css";

export default function Trip(props) {
    const navigate = useNavigate();

    const formatDate = (timestamp) => {
        var date = new Date(timestamp*1000);
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //Month indexing start with 0
        var yyyy = date.getFullYear();
        return dd + '.' + mm + '.' + yyyy;
    }

    const row = (<>
        <span className={"tripfield tripfield-tripname"}>{props.trip.name}</span>
        <span className={"tripfield tripfield-date"}>{formatDate(props.trip.timestamp)}</span>
        <span className={"tripfield tripfield-deleted " + (props.trip.deleted?"tripfield-negative":"tripfield-positive")}>{props.trip.deleted?"YES":"NO"}</span>
        <span className={"tripfield tripfield-actions"}>
            <button onClick={()=>navigate(`/trip/${props.trip.id}`)}>
                Details
            </button>
            <button onClick={()=>navigate(`/user/${props.trip.user_id}`)}>
                <img src={User} alt="User details"/>
            </button>
            <button onClick={()=>deleteRow()}>
                <img src={Delete} alt="Delete"/>
            </button>
        </span>
    </>);

    const deleteRow = () => {
        console.log("deleting trip");
        showConfirmationDialog(()=>{
            props.delete(props.token, props.trip.id).then((response)=>response?props.callback(props.token):null);
        });
    }

    return(
        <div id={props.id} className="trip">
            {row}
        </div>
    );
}