import TaiderRow from "../../components/TaiderRow/TaiderRow.js";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../components/TaiderAPI/TaiderAPI.js";
import { UserContext } from "../../views/App/App.js";
import "./Users.css";

export default function Users(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        loadUsers()
    }, []);
    
    const loadUsers = () => {
        if(taiderUser.userData == null){
            return;
        }
        getUsers(taiderUser.userData.token)
            .then(users => users?setRows(mapUsers(users)):null)
            .catch(handleError);
    };
    
    const mapUsers = (users) => {
        return users.map(user=>{
            return <TaiderRow 
                id={user.id} 
                key={user.id}
                row={[
                    {key: "avatar", value: user.picture, type: "picture"},
                    {key: "name", value: user.name, type: "text"},
                    {key: "email", value: user.email, size: 2, type: "text"},
                    {key: "tos_accepted", value: user.tos_accepted, size: 0.5, type: "checkbox"},
                    {key: "admin", value: user.tos_accepted, size: 0.5, type: "checkbox"},
                ]}
                details={()=>navigate(`/user/${user.id}`)} 
                token={taiderUser.userData.token} 
                callback={loadUsers}
            />
            });
    };

    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }

    const header = (<div className="header-row">
        <span className="usersheader usersheader-avatar"></span>
        <span className="usersheader usersheader-name">Name</span>
        <span className="usersheader usersheader-email usersheader-big">email</span>
        <span className="usersheader usersheader-tos usersheader-small">TOS</span>
        <span className="usersheader usersheader-admin usersheader-small">ADMIN</span>
        <span className="usersheader usersheader-actions">Actions</span>
    </div>);
    
    return (<div id="users">    
        {header}
        {rows}
    </div>);
}