import { UserContext } from "../../views/App/App.js";
import { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getTrips, getUserTrips, deleteTrip } from "../../components/TaiderAPI/TaiderAPI.js";
import Trip from "../../components/Trip/Trip.js";
import "./Trips.css";

export default function Trips(){
    const [taiderUser, ] = useContext(UserContext);
    const [rows, setRows] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("current id is " + id);
        loadTrips();
    }, [id]);

    const loadTrips = async () => {
        if(id != null){
            loadUserTrips(id);
            return;
        }
        if(taiderUser.userData == null){
            return;
        }
        getTrips(taiderUser.userData.token)
            .then(trips => trips?setRows(mapTrips(trips)):null)
            .catch(handleError);
    };

    const loadUserTrips = async (id) => {
        if(taiderUser.userData == null){
            return;
        }
        getUserTrips(taiderUser.userData.token, id)
            .then(trips => trips?setRows(mapTrips(trips)):null)
            .catch(redirectToAllTrips);
    };

    const mapTrips = (trips) => {
        console.log("mapping Trips");
        var mappedTrips = trips.map(trip => <Trip key={trip.id} token={taiderUser.userData.token} trip={trip} delete={deleteTrip} callback={loadTrips} />)
        return mappedTrips;
    };
    
    const handleError = (error) => {
        console.error(error);
        alert("API error, check console");
    }
    
    const redirectToAllTrips = () => {
        alert("User with such id does not exist");
        navigate("/trips");
    }

    const header = (<div className="header-row">
        <span className="tripsheader tripsheader-name">Name</span>
        <span className="tripsheader tripsheader-date">Date added</span>
        <span className="tripsheader tripsheader-deleted">Deleted?</span>
        <span className="tripsheader tripsheader-actions"></span>
    </div>);

    return (<div id="trips">
        {header}
        {rows}
    </div>)
}